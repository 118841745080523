.module--text {
  p {
    @include hyphenate;
    font-variation-settings: "opsz" 9;
    font-style: italic;
    font-size: rem(20px);
    line-height: calc(30/20);
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }

    @include typo_break--s {
      font-size: rem(30px);
      line-height: calc(45/30);
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  h2 {
    font-size: rem(66px);
    margin-bottom: 3rem;
  }
  h3 {
    margin-bottom: .6rem;
    font-size: rem(28px);
  }
}