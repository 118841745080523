.module--text.module--text--large {

  p {
    padding-left: $pad1--s;
    @include letter-spacing(-20);
    @include hyphenate;
    font-variation-settings: "opsz" 72;
    font-style: normal;
    font-size: rem(27px);
    line-height: calc(40/27);
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }

    @include typo_break--s {
      font-size: rem(46px);
      line-height: 1.32;
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
      }
    }

    @include layout_break--s {
      padding-left: $pad2--l;
    }
    @include layout_break--m {
      padding-left: $pad3--l;
    }

  }

  h3 {
    font-variation-settings: "opsz" 72;
    text-transform: uppercase;
    font-size: rem(30px);
    line-height: calc(32/30);
    margin-bottom: 20px;

    @include typo_break--s {
      font-size: rem(46px);
      line-height: calc(70/70);
    }
    @include layout_break--s {
      padding-left: $pad1--l;
      padding-right: $pad4--l;
    }
    @include layout_break--m {
      padding-right: $pad5--l;
    }
  }

}

.module--title + .module--text--large {
  margin-top: 30px;
  @include layout_break--s {
    margin-top: 60px;
  }
  @include layout_break--m {
    margin-top: 80px;
  }
}

.module--text.module--text--large {
  p + h3 {
    margin-top: 50px;
    @include layout_break--s {
      margin-top: 80px;
    }
    @include layout_break--m {
      margin-top: 120px;
    }
  }
}