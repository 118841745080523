h2 {
  @include letter-spacing(-30);
  @include hyphenate;
  font-size: rem(65px);
  line-height: calc(65/65);
  font-variation-settings: "opsz" 144;
  strong {
    @include letter-spacing(-70);
    text-transform: uppercase;
    font-weight: 400;
  }

  @include typo_break--s {
    font-size: rem(120px);
    line-height: calc(220/250);
  }

  @include typo_break--m {
    font-size: rem(150px);
  }

  @include typo_break--l {
    font-size: rem(200px);
  }

}