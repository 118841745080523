// Set core body defaults
body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}


// Prevent Animation onLoad
.preload-transitions * {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

//! BE NICE!

:focus {
  outline: 3px solid blue;
}

:focus:not(:focus-visible) {
  outline: 0;
}

:focus-visible {
  outline: 3px solid blue;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* For images to not be able to exceed their container */
img {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}