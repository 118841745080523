body {
  color: $color-main;
  font-family: "Fraunces", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-variation-settings: "opsz" 9;
  font-style: normal;
  font-variation-settings:
    "SOFT" 0,
    "WONK" 0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.logo {
  aspect-ratio: 260 / 164;
}