.linklist__item {
  margin-bottom: 1.4em;
}

.linklist__link {

  @include hyphenate;
  @include letter-spacing(-60);
  font-variation-settings: "opsz" 144;
  text-transform: uppercase;
  font-size: rem(50px);
  line-height: 0.9;

  padding-right: 70px;
  background-image: url(/theme/assets/dist/images/pfeil_kurz.svg);
  background-repeat: no-repeat;
  background-size: 60px 34px;
  background-position: right center;

  @include typo_break--s {
    font-size: rem(64px);
    padding-right: 100px;
    background-size: 88px 50px;
  }

  @include typo_break--m {
    font-size: rem(80px);
    padding-right: 140px;
    background-size: 117px 66px;
  }

  @include typo_break--l {
    font-size: rem(110px);
  }

}

.linklist__label {
  display: inline-block;
}