h3 {
  // @include hyphenate;
  font-variation-settings: "opsz" 72;
  font-size: rem(30px);
  line-height: calc(32/30);
  text-transform: uppercase;

  @include typo_break--s {
    font-size: rem(46px);
    line-height: 1;
  }

  @include typo_break--m {
    font-size: rem(60px);
  }

  }