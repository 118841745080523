.module--image {
  img {
    margin-bottom: 10px;

    @include layout_break--s {
      margin-bottom: 35px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}