$content-padding: 20px;

// ! Large

// * Grid
$col--l: 122px;
$gut--l: 16px;

// * Dimensions
$main-width--l: 12*$col--l + 11*$gut--l;
$content-width--l: $main-width--l + 2*$content-padding;

$col12--l: 12*$col--l + 11*$gut--l;
$col10--l: 10*$col--l + 9*$gut--l;
$col9--l: 9*$col--l + 8*$gut--l;
$col8--l: 8*$col--l + 7*$gut--l;
$col7--l: 7*$col--l + 6*$gut--l;
$col6--l: 6*$col--l + 5*$gut--l;
$col5--l: 5*$col--l + 4*$gut--l;
$col4--l: 4*$col--l + 3*$gut--l;
$col3--l: 3*$col--l + 2*$gut--l;
$col2--l: 2*$col--l + 1*$gut--l;
$col1--l: 1*$col--l + 1*$gut--l;

// * Padding
$pad1--l: percentage(calc($col1--l/$col12--l));
$pad2--l: percentage(calc($col2--l/$col12--l));
$pad3--l: percentage(calc($col3--l/$col12--l));
$pad4--l: percentage(calc($col4--l/$col12--l));
$pad5--l: percentage(calc($col5--l/$col12--l));

// ! Small

// * Grid
$col--s: 51px;
$gut--s: 9px;

$col6--s: 6*$col--s + 5*$gut--s;
$col4--s: 4*$col--s + 3*$gut--s;
$col3--s: 3*$col--s + 2*$gut--s;
$col2--s: 2*$col--s + 1*$gut--s;
$col1--s: 1*$col--s + 1*$gut--s;

// * Padding
$pad1--s: percentage(calc($col1--s/$col6--s));