@mixin inner-l--l {
  margin-right: auto;
  margin-left: auto;
  padding-right: $content-padding;
  padding-left: $content-padding;
  width: 100%;
  max-width: $col12--l + 2*$content-padding;
}

@mixin inner-l--s {
  margin-right: auto;
  margin-left: auto;
  padding-right: $content-padding;
  padding-left: $content-padding;
  width: 100%;
  max-width: $col6--s + 2*$content-padding;
}

@mixin inner-m--l {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: $col10--l + 2*$content-padding;
}

@mixin inner-s--l {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: $col6--l + 2*$content-padding;
}