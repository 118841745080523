

//* Title

.module--title + * {
  margin-top: 22px;
  @include typo_break--s {
    margin-top: 52px;
  }
}

.module--title:not(:first-child),
.module--newsletter {
  margin-top: 100px;
  @include layout_break--s {
    margin-top: 120px;
  }

  @include layout_break--m {
    margin-top: 160px;
  }
}

.module--title + .module--image {
  margin-top: -16px;

  @include layout_break--s {
    margin-top: -20px;
  }

  @include layout_break--m {
    margin-top: -40px;
  }

}

.module--subtitle + * {
  margin-top: 22px;

   @include typo_break--s {
    margin-top: 52px;
  }

}

.module--image + * {
  margin-top: 22px;
  @include layout_break--s {
    margin-top: 93px;
  }
}

.module--image + * {
  margin-top: 22px;
  @include typo_break--s {
    margin-top: 80px;
  }
}

.module--buttons {
  margin-top: 60px;
}

.module--text--large + .module--buttons {
  margin-top: 90px;
}

.module--linklist {
  margin-top: 200px;
}