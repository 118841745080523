body {
  background-color: $color-bg;
}

.wrapper {
  position: relative;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  @include inner-l--l();

}