.module--social {

  .social__list {
    margin-top: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;

    @include layout_break--s {
      margin-top: 80px;
      column-gap: 20px;
    }

  }

}