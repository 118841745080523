.form__input {
    -webkit-border-radius: 0px;
    border-radius: 0px;
    border: none;
    width: 100%;
    padding: 20px 20px;
    color: $color-main;
    background-color: $color-white;
    border: none;
    font-size: rem(30px);


    @include placeholder {
      font-size: rem(30px);
      color: $color-main;
    }

    &:focus {
        outline: none;
    }
    @include placeholder {
      color: $color-main;
    }
}

.form__submit {
  cursor: pointer;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    border: none;
    background-color: transparent;
}

.feedback {
    margin-top: 20px;
    font-variation-settings: "opsz" 9;
    font-style: italic;
    font-size: 2em;
    line-height: calc(30/20);
}

.feedback-error {
    color: red;
}

.success {
    color: $color-main;
}