.buttons__item {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }

  @include typo_break--s {
    margin-bottom: 64px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.buttons__link {
  font-variation-settings: "opsz" 72;
  font-style: italic;
  font-size: rem(40px);
  line-height: calc(45/40);

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @include typo_break--s {

    font-size: rem(70px);
    line-height: 1;

  }

  &:after {
    content:'';
    display:block;
    width: 216px;
    height: 66px;
    background-image: url(/theme/assets/dist/images/pfeil_lang.svg);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right center;


  }

}

.buttons__label {
  display: block;
  display: block;
  padding: 20px 5px;
  text-align: center;
  border: 2px solid $color-main;

  width: 447px;



}