$navBreakpoint: 866px;

$footerBreakpoint: 866px;

$typo_break--s: 600px;
$typo_break--m: 850px;
$typo_break--l: 1200px;

$layout_break--s: 768px;
$layout_break--m: 1000px;

@mixin nav-break {
  @media only screen and (min-width: $navBreakpoint) {
    @content;
  }
}

@mixin footer-break {
  @media only screen and (min-width: $footerBreakpoint) {
    @content;
  }
}

@mixin typo_break--s {
  @media only screen and (min-width: $typo_break--s) {
    @content;
  }
}

@mixin typo_break--m {
  @media only screen and (min-width: $typo_break--m) {
    @content;
  }
}

@mixin typo_break--l {
  @media only screen and (min-width: $typo_break--l) {
    @content;
  }
}

@mixin layout_break--s {
  @media only screen and (min-width: $layout_break--s) {
    @content;
  }
}

@mixin layout_break--m {
  @media only screen and (min-width: $layout_break--m) {
    @content;
  }
}