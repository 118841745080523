.social__list {
  display: grid;
}

.social__link {
  display: block;
  aspect-ratio: 1 / 1;
  text-indent: -9999px;
  overflow: hidden;
  // transition: all  0.3s ease-in-out;
  background-size: cover;
  &:hover {
    // transform: scale(1.1);
  }
}

//* Icons

.social__link__facebook {
  background-image: url('/theme/assets/dist/images/facebook_icon.svg');
}

.social__link__instagram {
  background-image: url('/theme/assets/dist/images/instagram_icon.svg');
}

.social__link__tripadvisor {
  background-image: url('/theme/assets/dist/images/tripadvisor_icon.svg');
}