.module {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.module--title {
  @include layout_break--s {
    padding-left: $pad1--l;
    padding-right: $pad1--l;
  }

}

.module--subtitle {

  @include layout_break--s {
    padding-left: $pad1--l;
    padding-right: $pad5--l;
  }
}

.module--text {

  padding-left: $pad1--s;

  @include layout_break--s {
    padding-left: $pad2--l;
    padding-right: $pad2--l;
  }

  @include layout_break--m {
    padding-left: $pad3--l;
    padding-right: $pad3--l;
  }

}

.module--newsletter {
  @include layout_break--m {
    padding-left: $pad1--l;
  }
}

.module--text.module--text--large {
  padding: 0;
  @include layout_break--s {
    padding: 0;
  }
  @include layout_break--m {
    padding: 0;
  }
}

.module--image {

  padding-left: $pad1--s;
  padding-right: $pad1--s;

  @include layout_break--s {
    padding-left: $pad2--l;
    padding-right: $pad2--l;
  }

  @include layout_break--m {
    padding-left: $pad3--l;
    padding-right: $pad3--l;
  }

}

.module--linklist {


  @include layout_break--s {
    padding-left: $pad1--s;
  }

  @include layout_break--m {
    padding-left: $pad3--l;
  }
}

.module--social {
  @include layout_break--s {
    padding-left: $pad1--l;
    padding-right: $pad1--l;
  }

  @include layout_break--m {
    padding-left: $pad1--l;
    padding-right: $pad2--l;
  }
}