.globalnav__list {

  @include nav-break {
    display: flex;
    align-items: flex-end;
    padding-top: 20px;
  }

}

.globalnav__item {
  margin-bottom: 20px;

  @include nav-break {
    margin-bottom: 0;
    margin-left: 30px;
    &:first-child {
      margin-left: 0;
    }
  }

}

.globalnav__item {
  @include hyphenate;
  font-variation-settings: "opsz" 144;
  font-size: rem(56px);
  line-height: 1.2;
  text-transform: uppercase;
  color: $color-white;

  @include nav-break {
    font-variation-settings: "opsz" 72;
    font-size: rem(28px);
    line-height: 1.2;
    text-transform: none;
    font-style: italic;
    color: $color-main;
  }

  @media only screen and (min-width: 1000px) {
    font-size: rem(32px);
  }

  @media only screen and (min-width: 1300px) {
    font-size: rem(35px);
  }
}

// ! Lang Switch

.lang-divide {
  font-variation-settings: "opsz" 144;
  font-weight: 700;
  color: $color-white;
  padding: 0 10px;
}

.globalnav__item.langswitch {
  @include nav-break {
    position: absolute;
    top: -2px;
    right: $content-padding;
    border: 2px solid $color-white;
  }
}

@include nav-break {

  .langswitch {
    margin: 0;
    margin-top: -10px;
    padding: 0 9px 3px 9px;
  }

  .globalnav__link--lang {

    font-variation-settings: "opsz" 9;
    font-size: rem(20px);
    line-height: 1;
    text-transform: uppercase;
    font-style: normal;

    &:first-child {
      margin-right: 6px;
    }

  }

  .lang-divide {
    display: none;
  }

}