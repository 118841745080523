/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default Styles */
body,
h1,
h2,
h3,
h4,
p,
a,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd,
button {
  all: unset;
  display: revert;
}

body {
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

/* Bring back the Pointers */
a,
button {
  cursor: pointer;
}

/* Remove list styles on ul, ol elements */
ul,
ol {
  list-style: none;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* fix the feature of 'hidden' attribute.
display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}