.globalfooter {

  padding-left: $pad1--l;
  padding-top: 200px;
  padding-bottom: 100px;

  @include footer-break {
    padding-left: 0;
    display: grid;
    grid-template-columns: (4fr 3fr 3fr 2fr);
    column-gap: $gut--l;
  }

  p, ul {
    @include hyphenate;
    font-variation-settings: "opsz" 72;
    @include letter-spacing(-20);
    font-style:normal;
    font-size: rem(26px);
    line-height: calc(44/26);
    margin-bottom: 0;
    @include footer-break {
      // font-size: rem(35px);
      // line-height: calc(60/35);
      font-size: rem(28px);
      line-height: 1.4;
    }
  }

  .social__list {
    margin-top: 16px;
    grid-template-columns: 73px 73px 73px;
    column-gap: 10px;
    .social__link {
      max-width: 73px ;
    }
  }

}

.globalfooter__group--contact,
.globalfooter__group--utility {
  margin-bottom: 60px;

  @include footer-break {
    margin-bottom: 0;
  }

}

.footer__link {
  // display: inline-block;
  padding-right: 50px;
  background-image: url(/theme/assets/dist/images/pfeil_kurz.svg);
  background-repeat: no-repeat;
  background-size: 40px 22px;
  background-position: right center;
}