.globalheader {

  margin-bottom: 50px;

  @include nav-break {
    // display: grid;
    // grid-template-columns: 260px 1fr;
    // align-items: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;
  }

}

// ! Logo

.globalheader__homelink {
  display: block;
  width: $col2--s;
  position: relative;
  z-index: 20;

  @include nav-break {
    // width: 260px;
    width: percentage(calc($col2--l/$col12--l));
  }

}

// ! Hamburger

.navtoggle {
  z-index: 20;
  position: absolute;
  top: 20px;
  right: 20px;
  &.active {
    position: fixed;
  }

  @include nav-break {
    display: none;
  }

}

// ! Navigation

// lock body
.nav-active {
  overflow-y: hidden;
  @include nav-break {
    overflow-y: scroll;
  }
}

// Mobile

.globalnav {
  padding: 140px $content-padding 0 $content-padding;
  background-color: $color-main;
  z-index: 10;
  position: fixed;
  top: -120%;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: all 0.3s ease;


  &.open {
    top: 0;
    max-height: 100%;
    overflow-y: auto;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */


  @include nav-break {
    all: revert;
  }
}